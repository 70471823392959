<template>
  <div
    class="rounded-full cursor-pointer border border-white px-2 py-0.5 text-white text-[9px] hover:scale-110 transition duration-300 select-none"
    @click.stop="audioPlayerNewStore.togglePlaybackRate()"
  >
    {{ audioPlayerNewStore.playbackRate }}x
  </div>
</template>

<script setup lang="ts">
import { useAudioPlayerNewStore } from '@/stores/audioPlayerNew'

const audioPlayerNewStore = useAudioPlayerNewStore()
</script>
